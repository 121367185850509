export default {
	getAll() {},
	findPatientByNumberDocument(id) {},
	find(id) {},
	history(id) {},
	save(id, data) {},
	delete(id) {},
	update(id, data) {},
	getAllByAreaDate() {},
	getAllExamByAreaDate() {},
}
